<template>
  <main>
    <section class="topsection">
      <div class="listviewbox">
        <div class="row m-0">
          <div class="col-md-5 p-0">
            <div class="listviewboxtop">
              <div
                class="searchBoxmain"
                v-bind:class="searchKeyEmpty ? 'searchBoxmainHover' : ''"
              >
                <form @submit.prevent="searchOnSubmitlist">
                  <input
                    type="text"
                    v-model="searchString"
                    @input="searchHandlerlist"
                    placeholder="Search River system, Town, or Facility"
                  />
                  <span v-if="showSearchBtn">
                    <button type="submit"><i class="fa fa-search"></i></button>
                  </span>
                  <span v-if="!showSearchBtn">
                    <button v-on:click="clearSearchBox()" type="button">
                      <i class="fa fa-times"></i>
                    </button>
                  </span>
                </form>
                <div v-show="isSearchResult">
                  <ul class="drodwn">
                    <li
                      v-for="searchItem of facilitiesSearch"
                      v-bind:key="searchItem.id"
                    >
                      <a
                        href="javascript:void(0)"
                        v-on:click="sendToFacility(searchItem)"
                      >
                        <span
                          :style="{
                            'font-weight':
                              searchItem.type === 1 ? 'bold' : '',
                          }"
                          >{{ searchItem.name }}</span
                        >
                        <span>{{ ", " }}</span>
                        <span
                          :style="{
                            'font-weight':
                              searchItem.type === 2 ? 'bold' : '',
                          }"
                          >{{ searchItem?.tb_riverSystem?.name }}</span
                        >
                        <span>{{ ", " }}</span>
                        <span
                          :style="{
                            'font-weight':
                              searchItem.type === 3 ? 'bold' : '',
                          }"
                          >{{ searchItem.nearTown }}</span
                        >
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <p>
                To ensure you have a
                <span>safe and enjoyable experience on the water</span>, view
                water levels, flow rates and safety information related to our
                hydropower facilities.
              </p>
            </div>
            <ul class="regionslist" id="infinite-region-list">
              <li>
                <a
                  href="javascript:void(0)"
                  @click="activate('all')"
                  :class="{ active: active_el == 'all' }"
                  v-on:click="getSingleRegions({ slug: 'all' })"
                  >All Regions
                </a>
              </li>
              <li v-for="item of regions" v-bind:key="item.id">
                <a
                  href="javascript:void(0)"
                  @click="activate(item.slug)"
                  :class="{ active: active_el == item.slug }"
                  v-on:click="getSingleRegions(item)"
                >
                  {{ item.name }}</a
                >
              </li>
            </ul>
          </div>
          <div class="col-md-7 p-0">
            <router-view />
          </div>
        </div>
      </div>
    </section>
    <section id="safeinfo">
      <div class="aboutsafeinfo">
        <HomepageNote />
      </div>
    </section>
  </main>
</template>

<script>
import HomepageSlider from "../components/homepageSlider";
import HomepageNote from "../components/homepageNote";
import customInstance from "../config";
import { getFacilitySearchData } from "../services/commonFunctionService";

export default {
  components: {
    HomepageSlider,
    HomepageNote,
  },
  data() {
    return {
      regions: [],
      errors: [],
      active_el: "",
      searchString: "",
      isSearchResult: false,
      facilitiesSearch: [],
      showSearchBtn: true,
      limit: 10,
      pageRegion: 1,
      hasMoreRegion: false,
      searchKeyEmpty: false,
    };
  },
  methods: {
    clearSearchBox() {
      this.showSearchBtn = true;
      this.searchString = "";
      this.isSearchResult = false;
      this.$router.push("/list/all");
    },

    searchHandlerlist() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        if (this.searchString != "") {
          customInstance
            .get("/filterfacilities?search=" + this.searchString)
            .then((response) => {
              if (response.data.status == 200) {
                this.facilitiesSearch = getFacilitySearchData(
                  response.data.data
                );
                this.isSearchResult = true;
              }

              if (response.data.status == 400) {
                this.facilitiesSearch = [];
                this.isSearchResult = false;
              }
            })
            .catch((e) => {
              this.errors.push(e);
            });
        } else {
          this.isSearchResult = false;
          this.showSearchBtn = true;
        }
      }, 500);
    },

    searchOnSubmitlist(e) {
      if (this.searchString != "") {
        this.isSearchResult = false;
        this.showSearchBtn = false;
        this.searchKeyEmpty = false;
        this.$router.push("/list/search/" + this.searchString);
      } else {
        this.searchKeyEmpty = true;
        setTimeout(() => {
          this.searchKeyEmpty = false;
        }, 500);
      }
    },
    activate: function (el) {
      this.active_el = el;
    },

    getAllRegions: function () {
      this.hasMoreRegion = false;
      let params = {
        limit: this.limit,
        page: this.pageRegion,
      };
      customInstance
        .get("/regions", { params })
        .then((response) => {
          if (response.data.status == 200) {
            this.regions = this.regions.concat(response.data.data);
            this.isHidden = false;
            this.showAlert = false;
            if (this.regions.length >= response.data.totalCount) {
              this.hasMoreRegion = false;
            } else {
              if (this.regions.length != "" || this.regions.length != null) {
                this.hasMoreRegion = true;
                this.regionSystemLoaded();
              }
            }
          }
          if (response.data.status == 400) {
            this.regions = [];
            this.isHidden = false;
            this.showAlert = false;
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },

    getSingleRegions: function (item) {
      if (item.slug != this.$route.params.regionSlug) {
        this.$router.push("/list/" + item.slug);
      }
    },
    loadMoreRegionSystem() {
      if (this.hasMoreRegion) {
        this.pageRegion = this.pageRegion + 1;
        this.getAllRegions();
      }
    },
    regionSystemLoaded() {
      this.$nextTick(() => {
        const listElm = document.querySelector("#infinite-region-list");
        listElm.addEventListener("scroll", (e) => {
          if (
            listElm.scrollTop + listElm.clientHeight >=
            listElm.scrollHeight
          ) {
            this.loadMoreRegionSystem();
          }
        });
      });
    },
    sendToFacility(fac) {
      this.$router.push("/facility/" + fac.slug);
    },
  },

  created() {
    this.getAllRegions();
  },
  mounted() {
    this.activate(this.$route.params.regionSlug);
  },
  beforeDestroy() {},
};
</script>
