<template>
  <div>
    <main>
      <div class="safenotic" v-if="showWarning">
        <i class="fa fa-exclamation-triangle"></i>
        <p>
          <b>CAUTION</b> - {{ currentDate }} - for {{ facilityInfo.name }} - We
          are experiencing difficulties and water data may not be up to date for
          this facility. We are working to resolve the issue as soon as
          possible.
        </p>
      </div>
      <section class="topsection">
        <div class="listviewbox">
          <div class="row m-0">
            <div class="col-md-5 p-0">
              <a @click="$router.back()" class="backlinklist"
                ><i class="fa fa-chevron-circle-left me-1"></i>Facilities
                List</a
              >
              <hr />
              <div class="singlefacleft" v-if="facilityInfo">
                <div
                  class="facleft"
                  v-bind:class="
                    facilityInfo.alertCount > 0
                      ? 'orange-alert'
                      : facilityInfo.notificationCount > 0
                      ? 'green-notification'
                      : ''
                  "
                >
                  <img
                    src="../assets/images/water-drops.png"
                    alt="Facilities"
                  />
                </div>
                <div class="facright">
                  <h1>{{ facilityInfo.name }}</h1>
                  <p>
                    <i class="fa fa-map-marker"></i>
                    {{
                      facilityInfo.nearTown
                        ? "Near " + facilityInfo.nearTown
                        : "NA"
                    }}
                  </p>
                </div>
              </div>
              <div class="singlefacleftdetails">
                <div
                  class="faceupdate"
                  v-if="
                    facilityInfo &&
                    facilityInfo.tb_facilityMatrices &&
                    facilityInfo.tb_facilityMatrices.length > 0
                  "
                >
                  <i class="fa fa-refresh"></i> Updated:
                  <span>{{ updateDate | formatDate1 }} ({{timeZoneAbbreviation}})</span>
                </div>
                <ul
                  class="singlefacedata nocursor"
                  v-if="
                    facilityInfo &&
                    facilityInfo.tb_facilityMatrices &&
                    facilityInfo.tb_facilityMatrices.length > 0
                  "
                >
                  <li
                    v-for="(
                      matrices, index
                    ) of facilityInfo.tb_facilityMatrices"
                    v-bind:key="index"
                    :class="
                      warningTag.length > 0 && isTagInclude(matrices.metricId)
                        ? 'flow-error'
                        : ''
                    "
                  >
                    <h1>
                      {{ matrices.value || "0" }}
                      <small v-if="matrices.time"
                        >{{ matrices.unit }} as of
                        {{ matrices.time | formatDate }} ({{timeZoneAbbreviation}})</small
                      >
                    </h1>
                    <p>{{ matrices.description }}</p>
                  </li>
                </ul>
                <hr />
                <div
                  class="facecontactinfo"
                  v-if="
                    facilityInfo &&
                    (facilityInfo.phone ||
                      (facilityInfo.email && facilityInfo.email != ''))
                  "
                >
                  <h1>FACILITY CONTACT</h1>
                  <a
                    :href="'tel:' + facilityInfo.phone"
                    class="tel"
                    v-if="facilityInfo.phone"
                    >{{ facilityInfo.phone }}</a
                  >
                  <a
                    :href="'mailto:' + facilityInfo.email"
                    v-if="facilityInfo.email && facilityInfo.email != ''"
                    >{{ facilityInfo.email }}</a
                  >
                  <hr />
                </div>
                <div
                  class="facecontactinfo"
                  v-if="
                    facilityInfo &&
                    facilityInfo.tb_facilitylinks &&
                    facilityInfo.tb_facilitylinks.length > 0
                  "
                >
                  <h1>MORE INFORMATION</h1>
                  <div
                    v-for="facilityLink of facilityInfo.tb_facilitylinks"
                    v-bind:key="facilityLink.id"
                  >
                    <h2
                      v-on:click="openSelectedLink(facilityLink)"
                      style="cursor: pointer"
                    >
                      {{ facilityLink.title }}
                    </h2>
                    <p>{{ facilityLink.desc }}</p>
                  </div>
                  <hr />
                </div>

                <div class="singlesafeinfo" v-if="facilityInfo">
                  <div
                    v-html="facilityInfo.message"
                    v-if="facilityInfo.message"
                  ></div>
                </div>
              </div>
            </div>
            <div class="col-md-7 p-0">
              <div class="singlefacright">
                <div class="addfav" v-if="addFavoritesStatus">
                  <a
                    v-if="!isLoggedIn"
                    href="javascript:void(0);"
                    @click="openModal('safelogin')"
                    ><i class="fa fa-star"></i>Add to Favorites</a
                  >

                  <a v-if="isLoggedIn" v-on:click="addTofavorites()"
                    ><i class="fa fa-star"></i>Add to Favorites</a
                  >
                </div>
                <div class="addfav" v-if="deleteFavoritesStatus">
                  <a v-on:click="deleteTofavorites()"
                    ><i class="fa fa-minus-circle"></i>Remove from Favorites</a
                  >
                </div>
                <hr />
                <div class="singlefacrightcontent">
                  <div class="noticationlist" v-if="facilityAlert.length > 0">
                    <div
                      class="noticationmain"
                      v-for="item of facilityAlert"
                      v-bind:key="item.id"
                      v-bind:class="
                        item.type === 'Alert' ? 'orange-border' : 'green-border'
                      "
                    >
                      <div
                        class="noticationhead"
                        v-bind:class="
                          item.type === 'Alert'
                            ? 'orange-background'
                            : 'green-background'
                        "
                      >
                        <i
                          class="fa fa-exclamation-triangle"
                          v-if="item.type === 'Alert'"
                        ></i>
                        <i
                          class="fa fa-bell"
                          v-if="item.type === 'Notifications'"
                        ></i>
                        <div>
                          <h1>{{ item.title }}</h1>
                          <p>{{ item.updateOn | formatDate1 }}</p>
                        </div>
                      </div>
                      <div class="noticationtext">
                        <p v-html="item.message"></p>
                      </div>
                    </div>
                  </div>

                  <div v-if="facilityInfo && facilityInfo.maintainLogs">
                    <h1>Gage height, ft</h1>
                    <div class="m-0 p-4">
                      <canvas id="planet-chart"></canvas>
                      <div class="text-center mt-4">
                        <p>
                          This gauge references the masonry crest of Eagle Falls
                          Dam.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div style="margin-bottom: 50px">
                    <h1 v-if="shortSchedule.length > 0">Short-term Schedule</h1>
                    <table v-if="shortSchedule.length > 0" class="tbSchedule">
                      <thead>
                        <tr>
                          <th>START</th>
                          <th>END</th>
                          <th v-if="shortScheduleGenerator">Generators</th>
                          <th>CFS</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item of shortSchedule" v-bind:key="item.id">
                          <td>{{ item.start | formatDate }}</td>
                          <td>{{ item.end | formatDate }}</td>
                          <td v-if="shortScheduleGenerator">
                            {{ item.generators > 0 ? item.generators : "N/A" }}
                          </td>
                          <td>{{ item.flow }}</td>
                        </tr>
                      </tbody>
                    </table>
                    <div
                      class="mt-4"
                      v-html="facilityInfo.shortTermMessage"
                      v-if="facilityInfo.shortTermMessage"
                    ></div>
                  </div>
                  <transition name="fade">
                    <div>
                      <h1 v-if="longSchedule.length > 0">Long-term Schedule</h1>
                      <table v-if="longSchedule.length > 0" class="tbSchedule">
                        <thead>
                          <tr>
                            <th>START</th>
                            <th>END</th>
                            <th v-if="longScheduleGenerator">Generators</th>
                            <th>CFS</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item of longSchedule" v-bind:key="item.id">
                            <td>{{ item.start | formatDate }}</td>
                            <td>{{ item.end | formatDate }}</td>
                            <td v-if="longScheduleGenerator">
                              {{ item.generators }}
                            </td>
                            <td>{{ item.flow }}</td>
                          </tr>
                        </tbody>
                      </table>
                      <div
                        class="mt-4"
                        v-html="facilityInfo.longTermMessge"
                        v-if="facilityInfo.longTermMessge"
                      ></div>
                    </div>
                  </transition>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="safeinfo">
        <div class="aboutsafeinfo">
          <HomepageNote />
        </div>
      </section>
    </main>
  </div>
</template>
<script>
import Vue from "vue";
import ToasterService from "../services/toasterService";
const toastMessage = new ToasterService();
import HomepageNote from "../components/homepageNote";
import moment from "moment-timezone";
import customInstance from "../config";
import { Modal } from "bootstrap";

Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(value).format("MM/DD/YYYY hh:mm A");
  }
});

Vue.filter("formatDate1", function (value) {
  if (value) {
    return moment(Number(value)).format("ddd, MMMM DD, hh:mm a");
  }
});

var graphUnit = "";

export default {
  components: {
    HomepageNote,
  },
  data() {
    return {
      addFavoritesStatus: false,
      deleteFavoritesStatus: false,
      facilityInfo: {},
      shortSchedule: [],
      longSchedule: [],
      facilityAlert: [],
      moment: moment,
      shortScheduleGenerator: false,
      longScheduleGenerator: false,
      updateDate: "",
      planetChartData: {},
      minAxisDate: "",
      maxAxisDate: "",
      showWarning: false,
      currentDate: "",
      shortAbbr: "",
      warningTag: [],
      timeZoneAbbreviation: ''
    };
  },
  computed: {
    isLoggedIn() {
      return this.$localStorage.token !== "";
    },
  },

  created() {
    this.getFacilityInfo();
  },
  mounted() {
    this.$eventBus.$on("socket-event", (data) => {
      if (data.facilityId === this.facilityInfo.id) {
        if (data.type === "short-term-schedule") {
          this.shortSchedule = data.data;
        }
        if (data.type === "long-term-schedule") {
          this.longSchedule = data.data;
        }
        if (data.type === "short-term-message") {
          this.facilityInfo.shortTermMessage = data.data.shortTermMessage;
        }
        if (data.type === "long-term-message") {
          this.facilityInfo.longTermMessge = data.data.longTermMessge;
        }
        if (data.type === "alert") {
          this.facilityAlert = data.data;
        }
        if (data.type === "facility-link") {
          this.facilityInfo.tb_facilitylinks = data.data;
        }
        if (data.type === "facility-message") {
          this.facilityInfo.message = data.data.message;
        }
        if (data.type === "facility-contact") {
          this.facilityInfo.phone = data.data.phone;
          this.facilityInfo.email = data.data.email;
        }
        if (data.type === "flow") {
          this.facilityInfo.tb_facilityMatrices = data.data;
          this.facilityInfo.tb_facilityMatrices.sort(
            (a, b) =>
              new Date(parseInt(b.updateOn)) - new Date(parseInt(a.updateOn))
          );

          this.updateDate = "";

          if (this.facilityInfo.tb_facilityMatrices.length > 0) {
            this.updateDate = this.facilityInfo.tb_facilityMatrices[0].updateOn;
          }
        }
      }
      console.log(">>>>>>>>>>>Facility>>>>>>>>>>", data);
      if (data.type === "facility-level-warning") {
        if (data.facilityIds.includes(this.facilityInfo.id)) {
          this.showWarning = data.data;
          this.warningTag = data.tags.map((e) => {
            return e.id;
          });
          this.currentDate =
            moment(new Date()).format("dddd, MMMM DD, YYYY, hh:mm A") +
            " " +
            this.shortAbbr;
        }
      }

      if (data.type === "healthy") {
        this.showWarning = data.data || false;
        this.warningTag = [];
      }
    });

    const abbr = String(String(new Date()).split("(")[1]).split(")")[0];
    var first_letter = function (x) {
      if (x) {
        return x[0];
      } else {
        return "";
      }
    };

    this.shortAbbr = abbr.split(" ").map(first_letter).join("");
    this.timeZoneAbbreviation = moment().tz(moment.tz.guess()).format('z');
  },
  beforeDestroy() {
    this.$eventBus.$off("socket-event");
  },
  methods: {
    openModal(modalId) {
      const modalElement = document.getElementById(modalId);
      const modalInstance = new Modal(modalElement, {
        backdrop: "static",
        keyboard: false,
      });
      modalInstance.show();
    },

    addTofavorites() {
      const facid = this.facilityInfo.id;
      if (this.$localStorage.token == "") {
        toastMessage.error("Please login and add to your favorite.");
      } else {
        const access_token = this.$localStorage.token;
        const body = {};
        const headers = { Authorization: `Bearer ${access_token}` };
        customInstance
          .post("/favourite/" + facid, body, { headers })
          .then((res) => {
            if (res.data.status == 200) {
              toastMessage.success("Added successfully.", {
                timeout: 2000,
                hideProgressBar: true,
              });
              this.deleteFavoritesStatus = true;
              this.addFavoritesStatus = false;
            }
            if (res.data.status == 400) {
              toastMessage.error(res.data.data);
              this.deleteFavoritesStatus = false;
              this.addFavoritesStatus = true;
            }
          });
      }
    },

    //get favority list
    getFavorites: function (facid) {
      const access_token = this.$localStorage.token;
      customInstance
        .get("/favourite/" + facid, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then((res) => {
          if (res.data.status == 200) {
            this.deleteFavoritesStatus = true;
          }
          if (res.data.status == 400) {
            this.addFavoritesStatus = true;
          }
        })
        .catch((error) => {
          console.error(error);
          this.addFavoritesStatus = true;
        });
    },
    deleteTofavorites() {
      const facid = this.facilityInfo.id;
      const access_token = this.$localStorage.token;
      customInstance
        .delete("/favourite/" + facid, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then((res) => {
          if (res.data.status == 200) {
            toastMessage.success(res.data.data);
            this.deleteFavoritesStatus = false;
            this.addFavoritesStatus = true;
          }
          if (res.data.status == 400) {
            toastMessage.error(res.data.data);
            this.addFavoritesStatus = false;
            this.deleteFavoritesStatus = true;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getFacilityInfo() {
      const facid = this.$route.params.id;
      customInstance
        .get("/facility/" + facid)
        .then((res) => {
          if (res.data.status == 200) {
            this.facilityInfo = res.data.data[0];
            document.title =
              this.facilityInfo.name +
              ", " +
              this.facilityInfo.region +
              " - Water Release Information | SafeWaters by Brookfield Renewable US";

            if (this.$localStorage.token != "") {
              this.getFavorites(this.facilityInfo.id);
            } else {
              this.addFavoritesStatus = true;
            }
            this.getFacilitySchedule(this.facilityInfo.id);
            this.getFacilityAlert(this.facilityInfo.id);
            if (this.facilityInfo.maintainLogs) {
              this.getChartPreview(this.facilityInfo.id);
            }

            this.facilityInfo.tb_facilityMatrices.sort(
              (a, b) =>
                new Date(parseInt(b.updateOn)) - new Date(parseInt(a.updateOn))
            );

            this.updateDate = "";

            if (this.facilityInfo.tb_facilityMatrices.length > 0) {
              this.updateDate =
                this.facilityInfo.tb_facilityMatrices[0].updateOn;
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getFacilityAlert(facid) {
      customInstance
        .get("public/alert/" + facid)
        .then((res) => {
          if (res.data.status == 200) {
            this.facilityAlert = res.data.data;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getFacilitySchedule(facid) {
      customInstance
        .get("/schedule/" + facid)
        .then((res) => {
          if (res.data.status == 200) {
            this.shortSchedule = res.data.data.filter((val) => {
              return val.type === "Short";
            });

            this.longSchedule = res.data.data.filter((val) => {
              return val.type === "Long";
            });

            let shortGeneratorFlag = this.shortSchedule.filter((val) => {
              return val.generators != null;
            });

            let longGeneratorFlag = this.longSchedule.filter((val) => {
              return val.generators != null;
            });

            this.shortScheduleGenerator =
              shortGeneratorFlag.length > 0 ? true : false;
            this.longScheduleGenerator =
              longGeneratorFlag.length > 0 ? true : false;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    openSelectedLink(link) {
      if (link.link.startsWith("http://") || link.link.startsWith("https://")) {
        window.open(link.link, "_blank");
      } else {
        window.open("//" + link.link, "_blank");
      }
    },
    getChartPreview(facid) {
      var d = new Date();
      let startDate = d.setDate(d.getDate() - 7);
      let endDate = Date.now();

      let startQuery = new Date(startDate).toISOString().replace("Z", "");
      let endQuery = new Date(endDate).toISOString().replace("Z", "");

      this.minAxisDate = moment(startDate).format("MMM DD");
      this.maxAxisDate = moment(endDate).format("MMM DD");

      customInstance
        .get(
          "getFacilitiesGraphValue?facilityId=" +
            facid +
            "&startDate=" +
            startQuery +
            "&endDate=" +
            endQuery
        )
        .then((res) => {
          if (res.data.status == 200) {
            graphUnit = res.data.data[0].data[0].unit;
            this.planetChartData = {
              type: "line",
              data: {
                datasets: [
                  {
                    label: "",
                    data: [],
                    fill: false,
                    borderColor: "#3581f7",
                    pointRadius: 1,
                    pointHoverRadius: 5,
                    borderWidth: 1,
                  },
                ],
              },
              options: {
                responsive: true,
                legend: {
                  display: false,
                },
                title: {
                  display: false,
                  text: "Chart.js Time Scale",
                },
                scales: {
                  xAxes: [
                    {
                      display: true,
                      type: "time",
                      time: {
                        parser: "MM/DD/YYYY HH:mm",
                        tooltipFormat: "ll HH:mm",
                        unit: "day",
                        unitStepSize: 1,
                        displayFormats: {
                          day: "MMM DD",
                        },
                      },
                    },
                  ],
                  yAxes: [
                    {
                      display: true,
                      ticks: {
                        callback: function (item) {
                          return item.toFixed(2) + " " + graphUnit;
                        },
                        stepSize: 1,
                      },
                    },
                  ],
                },
                tooltips: {
                  callbacks: {
                    label: function (tooltipItem, data) {
                      let label =
                        data.datasets[tooltipItem.datasetIndex].label || "";

                      if (label) {
                        label += ": ";
                      }
                      label += tooltipItem.yLabel + " " + graphUnit;
                      return label;
                    },
                  },
                },
                hover: {
                  onHover: function (event, elements) {
                    console.log("????????", event, "<<<<<<", elements);
                  },
                },
              },
            };

            this.planetChartData.data.datasets[0].data =
              res.data.data[0].data.map((e) => {
                return {
                  x: moment(new Date(e.time)).format("MM/DD/YYYY HH:mm:ss"),
                  y: parseFloat(e.value),
                };
              });

            const ctx = document.getElementById("planet-chart");
            new Chart(ctx, this.planetChartData);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    isTagInclude(tagId) {
      return this.warningTag.includes(tagId);
    },
  },
};
</script>
<style type="text/css">
.fade-enter {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-out;
}

.fade-leave-to {
  opacity: 0;
}
</style>

<style>
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Open Sans", sans-serif;
  line-height: 125.18%;
  color: #0f3557;
  font-weight: bold;
}
h1 {
  font-size: 24px;
}
h2,
.singlefacrightcontent h2 {
  font-size: 24px;
}
.singlefacrightcontent h3 {
  font-size: 22px;
}
.singlefacrightcontent h4 {
  font-size: 18px;
}
h5 {
  font-size: 16px;
}
h6 {
  font-size: 14px;
}
table th,
table td {
  border: 2px solid #e0e5ea;
}
table th {
  padding: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 125.18%;
  color: #000000;
}
table td {
  padding: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 125.18%;
  color: #374e60;
}
.singlefacrightcontent table.tbSchedule th,
.singlefacrightcontent table.tbSchedule td {
  border: 0;
}

.backlinklist a:hover {
  color: #3581f7 !important;
}

.safenotic {
  background-color: #f8b96b;
  color: #b66200;
  right: 0;
  display: block;
  font-size: 14px;
  padding: 15px;
  border-bottom: 1px solid #e57c01;
  margin: 0 auto;
  margin-right: 0;
  position: sticky !important;
  top: 0;
  z-index: 9;
}
.safenotic .fa {
  display: inline-block;
  font-size: 38px;
  vertical-align: middle;
  width: 60px;
}
.safenotic p {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  width: calc(100% - 62px);
}

@media (max-width: 767px) {
  .safenotic {
    margin-left: 70px;
  }

  .safenotic-width {
    width: calc(100% - 70px) !important;
  }
}

.flow-error {
  background: #f8b96b !important;
}

.safenotic-width {
  width: calc(100% - 112px);
}
</style>
