import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import listview from "../views/list-view.vue";
import mapview from "../views/map-view.vue";
import favorites from "../views/favorites.vue";
import facilitylist from "../views/facility";
import preview from "../views/preview";
import Terms from "../views/Terms";
import TermDataProtection from "../views/TermDataProtection";
import TermCookiePolicy from "../views/TermCookiePolicy";
import ListRiverSystem from "../views/ListRiverSystem";
import ListFacility from "../views/ListFacility";
import PublicSafety from "../views/PublicSafety";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/list",
    name: "listview",
    component: listview,
    children: [
      {
        path: ":regionSlug",
        component: ListRiverSystem,
      },
      {
        path: ":regionSlug/:riverSystemSlug",
        component: ListFacility,
      },
      {
        path: "search/:searchKey",
        component: ListFacility,
      },
    ],
  },
  {
    path: "/map",
    name: "map",
    component: mapview,
  },
  {
    path: "/map/:regionId",
    name: "map-river",
    component: mapview,
  },
  {
    path: "/map/:regionId/:riverSystemId",
    name: "map-facility",
    component: mapview,
  },
  {
    path: "/favorites",
    name: "favorites",
    component: favorites,
  },
  {
    path: "/facility/:id",
    name: "facility",
    component: facilitylist,
  },
  {
    path: "/preview/:previewId",
    name: "preview",
    component: preview,
  },
  {
    path: "/terms",
    name: "terms",
    component: Terms,
  },
  {
    path: "/terms-data-protection",
    name: "data-protection",
    component: TermDataProtection,
  },
  {
    path: "/term-cookie-policy",
    name: "TermCookiePolicy",
    component: TermCookiePolicy,
  },
  {
    path: "/public-safety",
    name: "PublicSafety",
    component: PublicSafety,
  },
  { path: "*", redirect: "/" },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL, // Not in use
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (!(to.fullPath.includes("/terms") && to.query.aboutOurAds !== undefined)) {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("token") == null) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath },
      });
    } else {
      let user = JSON.parse(localStorage.getItem("fullname"));
      if (to.matched.some((record) => record.meta.is_admin)) {
        if (user.is_admin == 1) {
          next();
        } else {
          next({ name: "userboard" });
        }
      } else {
        next();
      }
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (localStorage.getItem("token") == null) {
      next();
    } else {
      next({ name: "userboard" });
    }
  } else {
    next();
  }
});

export default router;
