var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[_c('section',{staticClass:"topsection"},[_c('div',{staticClass:"listviewbox"},[_c('div',{staticClass:"row m-0"},[_c('div',{staticClass:"col-md-5 p-0"},[_c('div',{staticClass:"listviewboxtop"},[_c('div',{staticClass:"searchBoxmain",class:_vm.searchKeyEmpty ? 'searchBoxmainHover' : ''},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.searchOnSubmitlist.apply(null, arguments)}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchString),expression:"searchString"}],attrs:{"type":"text","placeholder":"Search River system, Town, or Facility"},domProps:{"value":(_vm.searchString)},on:{"input":[function($event){if($event.target.composing)return;_vm.searchString=$event.target.value},_vm.searchHandlerlist]}}),(_vm.showSearchBtn)?_c('span',[_vm._m(0)]):_vm._e(),(!_vm.showSearchBtn)?_c('span',[_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.clearSearchBox()}}},[_c('i',{staticClass:"fa fa-times"})])]):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSearchResult),expression:"isSearchResult"}]},[_c('ul',{staticClass:"drodwn"},_vm._l((_vm.facilitiesSearch),function(searchItem){return _c('li',{key:searchItem.id},[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.sendToFacility(searchItem)}}},[_c('span',{style:({
                          'font-weight':
                            searchItem.type === 1 ? 'bold' : '',
                        })},[_vm._v(_vm._s(searchItem.name))]),_c('span',[_vm._v(_vm._s(", "))]),_c('span',{style:({
                          'font-weight':
                            searchItem.type === 2 ? 'bold' : '',
                        })},[_vm._v(_vm._s(searchItem?.tb_riverSystem?.name))]),_c('span',[_vm._v(_vm._s(", "))]),_c('span',{style:({
                          'font-weight':
                            searchItem.type === 3 ? 'bold' : '',
                        })},[_vm._v(_vm._s(searchItem.nearTown))])])])}),0)])]),_vm._m(1)]),_c('ul',{staticClass:"regionslist",attrs:{"id":"infinite-region-list"}},[_c('li',[_c('a',{class:{ active: _vm.active_el == 'all' },attrs:{"href":"javascript:void(0)"},on:{"click":[function($event){return _vm.activate('all')},function($event){return _vm.getSingleRegions({ slug: 'all' })}]}},[_vm._v("All Regions ")])]),_vm._l((_vm.regions),function(item){return _c('li',{key:item.id},[_c('a',{class:{ active: _vm.active_el == item.slug },attrs:{"href":"javascript:void(0)"},on:{"click":[function($event){return _vm.activate(item.slug)},function($event){return _vm.getSingleRegions(item)}]}},[_vm._v(" "+_vm._s(item.name))])])})],2)]),_c('div',{staticClass:"col-md-7 p-0"},[_c('router-view')],1)])])]),_c('section',{attrs:{"id":"safeinfo"}},[_c('div',{staticClass:"aboutsafeinfo"},[_c('HomepageNote')],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{attrs:{"type":"submit"}},[_c('i',{staticClass:"fa fa-search"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" To ensure you have a "),_c('span',[_vm._v("safe and enjoyable experience on the water")]),_vm._v(", view water levels, flow rates and safety information related to our hydropower facilities. ")])
}]

export { render, staticRenderFns }