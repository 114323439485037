<template>
  <div>
    <div id="homecarousel" class="carousel slide" data-bs-ride="carousel">
      <!-- The slideshow -->
      <div class="carousel-inner" v-if="sliderInfo.length > 0">
        <div
          class="carousel-item"
          v-for="(slider, index) in sliderInfo"
          :key="index"
          :class="{ active: index === 0 }"
        >
          <div class="row besafe">
            <div class="col-md-6">
              <img :src="slider.imageUrl" alt="image" />
            </div>
            <div class="col-md-6">
              <div class="besafeinner">
                <h1>{{ slider.title }}</h1>
                <div v-html="slider.content" class="sliderContent"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Left and right controls -->
      <a
        class="carousel-control-prev"
        data-bs-target="#homecarousel"
        data-bs-slide="prev"
        href="javascript:void(0);"
      >
        <span class="carousel-control-prev-icon"></span>
      </a>
      <a
        class="carousel-control-next"
        data-bs-target="#homecarousel"
        data-bs-slide="next"
        href="javascript:void(0);"
      >
        <span class="carousel-control-next-icon"></span>
      </a>

      <!-- Indicators -->
      <ul class="carousel-indicators">
        <li
          data-bs-target="#homecarousel"
          v-for="(slider, index) in sliderInfo"
          :key="index"
          :class="{ active: index === 0 }"
          :data-bs-slide-to="index"
        ></li>
      </ul>
    </div>
  </div>
</template>

<script>
import customInstance from "../config";

export default {
  name: "HomepageSlider",
  data() {
    return {
      bannerInfo: {},
      sliderInfo: [],
    };
  },
  methods: {
    getBannerSlider: function () {
      customInstance
        .get("/admin/BannerCarousel")
        .then((response) => {
          if (response.data.status == 200) {
            this.bannerInfo = response.data.data.bannerJson;
            this.sliderInfo = response.data.data.sliderJson;
            if (window.location.pathname == "/") {
              this.$emit("bannerImage", this.bannerInfo);
            }
          }
          if (response.data.status == 400) {
            this.bannerInfo = {};
            this.sliderInfo = [];
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
  created() {
    this.getBannerSlider();
  },
};
</script>
<style scoped>
.carousel-control-next-icon,
.carousel-control-prev-icon {
  width: 20px;
  height: 20px;
}

.carousel-indicators li::marker {
  font-size: 0;
}
</style>