<template>
  <main>
    <section class="topsection" v-bind:style="styleObject">
      <div class="aboutsafe">
        <div class="logotext">
          <img
            src="../assets/images/logo-full.png"
            alt="Safe-waters"
            width="440px"
          />
          <p>
            To ensure you have a
            <span>safe and enjoyable experience on the water</span>, view water
            levels, flow rates and safety information related to our hydropower
            facilities.
          </p>
        </div>
        <div
          class="searchBoxmain"
          v-bind:class="searchKeyEmpty ? 'searchBoxmainHover' : ''"
        >
          <form @submit.prevent="searchOnSubmit">
            <input
              type="text"
              v-model="filterKey"
              @input="searchHandler"
              placeholder="Search River system, Town, or Facility"
            />
            <span v-if="fullPage"></span>
            <span>
              <button type="submit">
                <i class="fa fa-search"></i>&nbsp;Search
              </button>
            </span>
          </form>
          <div v-show="isSearchResult">
            <ul class="drodwn">
              <li
                v-for="facilitiesSearchItem of facilitiesSearch"
                v-bind:key="facilitiesSearchItem.id"
              >
                <router-link :to="'/facility/' + facilitiesSearchItem.slug">
                  <span :style="{'font-weight': facilitiesSearchItem.type === 1 ? 'bold' : ''}">{{facilitiesSearchItem.name}}</span>
                  <span>{{', '}}</span>
                  <span :style="{'font-weight': facilitiesSearchItem.type === 2 ? 'bold' : ''}">{{facilitiesSearchItem?.tb_riverSystem?.name}}</span>
                  <span>{{', '}}</span>
                  <span :style="{'font-weight': facilitiesSearchItem.type === 3 ? 'bold' : ''}">{{facilitiesSearchItem.nearTown}}</span>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="viewrecent">
          <div class="head">
            Latest
            <span
              >All Facilities: <router-link :to="'map'">Map </router-link>
              <router-link :to="'list/all'">List</router-link></span
            >
          </div>
          <div
            v-for="facilities of facilitiesList"
            v-bind:key="facilities.id"
            v-bind:id="facilities.id"
          >
            <router-link
              :to="'/facility/' + facilities.slug"
              class="row rfacilities"
            >
              <div class="col-md-1">
                <div
                  class="dropsicon"
                  v-bind:class="
                    facilities.alertCount > 0
                      ? 'orange-alert'
                      : facilities.notificationCount > 0
                      ? 'green-notification'
                      : ''
                  "
                >
                  <img
                    src="../assets/images/water-drops.png"
                    alt="Facilities"
                  />
                </div>
              </div>
              <div class="col-md-5">
                <h1>{{ facilities.name }}</h1>
                <p>
                  <i class="fa fa-map-marker"></i> Near
                  {{ facilities.nearTown }}
                </p>
              </div>
              <div
                class="col-md-3"
                v-for="(matrices, index) of facilities.tb_facilityMatrices"
                v-bind:key="index"
              >
                <h1>
                  {{ matrices.value || "0" }} <small>{{ matrices.unit }}</small>
                </h1>
                <p>{{ matrices.description }}</p>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <section id="safeinfo">
      <div class="aboutsafeinfo">
        <HomepageSlider @bannerImage="getBannerImage" />
        <HomepageNote />
      </div>
    </section>
  </main>
</template>

<script>
import _ from "lodash";
import HomepageSlider from "../components/homepageSlider";
import HomepageNote from "../components/homepageNote";
import customInstance from "../config";
import { getFacilitySearchData } from "../services/commonFunctionService";

export default {
  components: {
    HomepageSlider,
    HomepageNote,
  },
  data() {
    return {
      facilitiesList: [],
      filterKey: "",
      isSearchResult: false,
      timer: null,
      facilitiesSearch: [],
      submitted: false,
      fullPage: false,
      searchKeyEmpty: false,
      styleObject: {
        "background-image": "",
      },
    };
  },
  methods: {
    getBannerImage(image) {
      if (image.websiteShow) {
        this.styleObject = {
          "background-image": "url(" + image.imageUrl + ")",
        };
      }
    },

    searchHandler() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        if (this.filterKey != "") {
          customInstance
            .get("/filterfacilities?search=" + this.filterKey)
            .then((response) => {
              if (response.data.status == 200) {
                this.facilitiesSearch = getFacilitySearchData(
                  response.data.data
                );
                this.isSearchResult = true;
              }
              if (response.data.status == 400) {
                this.facilitiesSearch = [];
                this.isSearchResult = false;
              }
            })
            .catch((e) => {
              this.errors.push(e);
            });
        } else {
          this.isSearchResult = false;
        }
      }, 500);
    },
    searchOnSubmit(e) {
      if (this.filterKey != "") {
        this.submitted = true;
        this.searchKeyEmpty = false;
        this.$router.push({ path: "/list/search/" + this.filterKey });
      } else {
        this.searchKeyEmpty = true;
        setTimeout(() => {
          this.searchKeyEmpty = false;
        }, 500);
      }
    },

    getFacilitiesByID: function () {
      customInstance
        .get("/viwed_recently?page=1&sortOn=createOn&sortType=desc&limit=5")
        .then((response) => {
          if (response.data.status == 200) {
            this.facilitiesList = response.data.data;
          }
          if (response.data.status == 400) {
            this.facilitiesList = [];
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },

  created() {
    this.getFacilitiesByID();
    document.title = "Home | SafeWaters by Brookfield Renewable US";
  },
  mounted() {
    this.$eventBus.$on("socket-event", (data) => {
      if (data.type === "flow") {
        let index = this.facilitiesList.findIndex(
          (item) => item.id === data.facilityId
        );
        if (index > -1) {
          const temp = data.data.sort(function (x, y) {
            return y.updateOn - x.updateOn;
          });
          this.facilitiesList[index].tb_facilityMatrices = temp.slice(0, 2);
        }
      }
    });
  },
  beforeDestroy() {
    this.$eventBus.$off("socket-event");
  },
};
</script>

<style>
.dropdown-toggle::after {
  display: none !important;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: none !important;
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.searchBoxmain {
  position: relative;
}
</style>
